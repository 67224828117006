var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-wrapper-header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("ESTIMATE_VEHICLES.MAKE_VEHICLE_ESTIMATION"))+" ")])]),_c('div',{staticClass:"col-6 text-right"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_PACKAGES))?_c('notification-subscription',{attrs:{"objectType":'packages',"events":{
              CREATE: _vm.$t('NOTIFICATIONS.EVENT_CREATE'),
              UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
              DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
            }}}):_vm._e()],1)])]),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_VEHICLE_ESTIMATIONS)
      )?_c('div',{staticClass:"page-wrapper-table"},[_c('view-estimate-component')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }